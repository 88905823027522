<template>
  <div>
    <v-avatar
      size="162"
      class="ava"
    >
      <span
        class="d-block red--text mt-3"
        style="width: 180px; text-align: center"
        v-if="fileSizeError"
      >{{fileSizeError}}
      </span>
      <ImageView
        :src="thumbSrc(images)"
        class="align-center"
      />
      <v-icon
        color="white"
        class="ava-icon"
      >mdi-camera
      </v-icon>
    </v-avatar>
    <v-btn
      class="mt-3 d-block"
      :loading="isUploading"
    >
      Загрузить фото
      <input
        :disabled="isUploading"
        :name="uploadFieldName"
        @change="filesChange($event.target.name, $event)"
        accept="image/*"
        class="input-file"
        type="file"
      >
    </v-btn>
  </div>
</template>

<script>
import image from '@/components/mixins/image'
import imageUpload from '@/components/mixins/imageUpload'
import ImageView from '@/components/views/Common/ImageView'

export default {
  mixins: [image, imageUpload],
  name: 'UploadAvatar',
  components: { ImageView },
  data () {
    return {
      imagesType: 'Object'
    }
  }
}
</script>

<style>
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  .ava {
    position: relative;
  }
  .ava-icon {
    position: absolute !important;
    opacity: 0.3;
  }
</style>

<template>
  <v-card outlined>
    <v-card-title>Сменить пароль</v-card-title>
    <v-card-text>
      <v-text-field
        label="Старый пароль"
        autocomplete="new-password"
        v-model="$v.password.oldPass.$model"
        :error-messages="checkErrors('password.oldPass')"
        type="password"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-text-field
        label="Новый пароль"
        v-model="$v.password.newPass.$model"
        :error-messages="checkErrors('password.newPass')"
        type="password"
      ></v-text-field>
      <v-text-field
        label="Подтверждение пароля"
        v-model="password.repeatPass"
        :error-messages="checkErrors('password.repeatPass')"
        type="password"
      ></v-text-field>
      <div class="text-right">
        <v-btn
          class="primary mr-2"
          small
          :loading="isSaving"
          @click="handleChangePassword"
        > Изменить
        </v-btn>
        <v-btn
          class="mr-2"
          small
        > Отмена
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import validationErrors from '@/components/mixins/validationErrors'
import { minLength, required, sameAs } from 'vuelidate/lib/validators'

export default {
  mixins: [validationErrors],
  name: 'PasswordChange',
  data () {
    return {
      password: {
        oldPass: null,
        newPass: null,
        repeatPass: null
      },
      isSaving: false
    }
  },
  validations: {
    password: {
      oldPass: {
        required
      },
      newPass: {
        required,
        minLength: minLength(8)
      },
      repeatPass: {
        required,
        sameAsPassword: sameAs(function () {
          return this.password.newPass
        })
      }
    }
  },
  methods: {
    async handleChangePassword () {
      if (!this.isSaving) {
        this.isSaving = true
        this.$v.password.$touch()
        if (this.$v.password.$anyDirty && !this.$v.password.$anyError()) {
          await this.$store.dispatch('auth/changePassword', {
            data: {
              password: this.password.oldPass,
              newPassword: this.password.newPass,
              repeatPassword: this.password.repeatPass
            }
          })
          this.$v.password.$reset()
        }
        this.isSaving = false
      }

      return true
    }
  }
}
</script>

<style scoped>

</style>

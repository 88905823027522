<template>
  <div class="section section-main-100 ">
    <div
      class="text-center d-flex justify-center align-center"
      style="height: 80vh;"
      v-if="isLoading"
    >
      <div>
        <v-progress-circular
          class="text-center"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>
    <v-container
      v-else
      class="pt-5"
    >
      <div class="title">Мой профиль</div>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <UploadAvatar
            v-model="detail.avatar"
            url="/image/user"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div class="width-70 d-flex">
            <v-text-field
              outlined
              dense
              label="Имя"
              v-model="detail.username"
              :error-messages="checkErrors('detail.username')"
              @focus="$event.target.select()"
            ></v-text-field>
          </div>
          <div class="width-70 d-flex">
            <v-text-field
              outlined
              dense
              label="Е-mail"
              v-model="detail.email"
              :error-messages="checkErrors('detail.email')"
              @focus="$event.target.select()"
            ></v-text-field>
          </div>
          <div class="width-70 d-flex">
            <PhoneList
              v-model="detail.userPhones"
            />
          </div>
          <v-btn
            rounded
            color="success mr-1"
            :loading="isSaving"
            @click="handleSave"
          >Сохранить
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <PasswordChange/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import UploadAvatar from '@/components/views/Account/Profile/UploadAvatar'
import PasswordChange from '@/components/views/Account/Profile/PasswordChange'
import { email, minLength, required } from 'vuelidate/lib/validators'
import validationErrors from '@/components/mixins/validationErrors'
import PhoneList from '@/components/views/Account/Profile/PhoneList'

export default {
  mixins: [validationErrors],
  name: 'Profile',
  components: { PhoneList, PasswordChange, UploadAvatar },
  data () {
    return {
      detail: this.$store.getters['auth/getProfile'],
      isSaving: false
    }
  },
  provide () {
    return {
      $v: this.$v
    }
  },
  validations: {
    detail: {
      username: {
        required
      },
      email: {
        required,
        email,
        isUnique (value) {
          let result = true
          if (this.$v.detail.email.$dirty) {
            result = this.$store.dispatch('auth/isUnique', {
              data: {
                email: value,
                id: this.detail.id
              }
            })
          }
          return result
        }
      },
      userPhones: {
        required,
        $each: {
          phone: {
            phone: {
              required,
              minLength: minLength(11)
            }
          }
        }
      }
    }
  },
  computed: {
    isLoading () {
      return !this.$store.getters['auth/getProfile']
    }
  },
  watch: {
    isLoading () {
      this.detail = this.$store.getters['auth/getProfile']
    }
  },
  methods: {
    async handleSave () {
      if (!this.isSaving) {
        this.isSaving = true
        this.$v.detail.$touch()
        if (await this.checkPending() && !this.$v.detail.$anyError) {
          await this.$store.dispatch('auth/saveProfile', {
            data: this.detail
          })
        }
        this.isSaving = false
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>
